module.exports = {
  siteTitle: 'Kristof Ringleff',
  siteDescription: ``,
  authorName: 'Kristof Ringleff',
  twitterUsername: 'foomanNZ',
  githubUsername: 'fooman',
  authorAvatar: '/images/Kristof_1000x1000.jpg',
  authorDescription: `Husband, Father and Traveller. I also develop code to help people with their online business.`,
  skills: [
    {
      name: 'Magento 2',
      level: 80
    },
    {
      name: 'Magento 1',
      level: 90
    },

  ],
  jobs: [
    {
      company: "in New Zealand",
      begin: {
        month: '',
        year: '2004'
      },
      duration: "3 years",
      occupation: "Various",
      description: ""
    },
    {
      company: "Fooman",
      begin: {
        month: 'Aug',
        year: '2007'
      },
      duration: null,
      occupation: "Founder & Lead Developer",
      description: "Creator of Magento extensions"
    }
  ],
  social: {
    twitter: "https://twitter.com/foomanNZ",
    linkedin: "https://www.linkedin.com/in/kristofringleff/",
    github: "https://github.com/fooman",
    email: "kristof@fooman.co.nz"
  },
  siteUrl: 'https://ringleff.net',
  pathPrefix: '/', // Note: it must *not* have a trailing slash.
  siteCover: '/images/20190210_081740.jpg',
  googleAnalyticsId: '',
  background_color: '#ffffff',
  theme_color: '#25303B',
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
  headerLinks: [
    {
      label: 'Kristof Ringleff',
      url: '/',
    }
  ]
}